import APP_CONST from '@/constants/AppConst';
import analyticsStore from '@/store/modules/analyticsStore';
import { Vue, Component } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';

@Component({
    components: {
    }
  })


export default class FamilyEngagementAnalyticsComponent extends Vue {
    public familyTabs = APP_CONST.ANALYTICS_FAMILY_ENGAGEMENT_TAB;
    private objScreenText: ScreenText = new ScreenText();

    get selectedFamilyEngagementTab(){
      return analyticsStore.selectedFamilyEngagementTabValue
    }

    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

    setNavigation(url: string, key: string){
      this.$router.push(url);
  }
}